[data-reach-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.33);
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*top: var(--modalTop);*/
    /*right: var(--modalRight);*/
    overflow: auto;
}

:root{
    --modalTop: 100px;
    --modalRight: 100px;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 6px!important;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2)!important;
    border-radius: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px -moz-mac-chrome-inactive!important;
    border-radius: 10px;
}
