.hct-timeline {
  box-sizing: border-box;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}

.hct-timeline-item {
  position: relative;
  margin: 0;
  font-size: 14px;
  height: 108px;
  list-style: none;
}
.hct-timeline-item-max{
  height: 128px;
} 
.hct-timeline-item-tail {
  position: absolute;
  top: 20px;
  left: 2px;
  height: calc(100% - 6px);
  border-left: 2px solid #E5E3E9;
}

.hct-timeline-item-completed .hct-timeline-item-tail{
  border-color: #07c270;
}

.hct-timeline-item-last>.hct-timeline-item-tail {
  display: none;
}

.hct-timeline.hct-timeline-pending .hct-timeline-item-last .hct-timeline-item-tail {
  display: block;
  height: calc(100% - 6px);
  border-left: 2px dotted #E5E3E9;
}

.hct-timeline-item-head {
  position: absolute;
  top: 14px;
  width: 6px;
  height: 6px;
  background-color: #E5E3E9;
  border: 2px solid transparent;
  border-radius: 100px;
}

.hct-timeline-item-head-green {
  border-color: #06C270;
  color: #06C270;
  background-color: #06C270;
}

.hct-timeline-item-head-red {
  border-color: #E53535;
  color: #E53535;
  background-color: #E53535;
}
.hct-timeline-item-head-yellow {
  border-color: #E5B800;
  color: #E5B800;
  background-color: #E5B800;
}

.hct-timeline-item-head-blue {
  border-color: #1890ff;
  color: #1890ff;
  background-color: #1890ff;
}
.hct-timeline-item-pending .hct-timeline-item-head,
.hct-timeline-item-completed .hct-timeline-item-head,
.hct-timeline-item-success .hct-timeline-item-head,
.hct-timeline-item-loading .hct-timeline-item-head{
  background-color: #06C270;
}
.hct-timeline-item-error .hct-timeline-item-head{
  background-color:#e63635
}
.hct-timeline-item-warning .hct-timeline-item-head{
  background-color:#E5B800
}
.hct-timeline-item-content {
  position: relative;
  margin: 0 0 0 26px;
  word-break: break-word;
  top: -2px;
}

.timeline-item-content-flex {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
.hct-timeline-logo-wrap{
  height: 30px;
}
.hct-timeline-logo {
  /* border: 5px solid #fff; */
  border-radius: 50%;
  margin-right: 6px ;
}
.hct-status-icon-wrap{
  margin-right: 16px;
}
.hct-status-icon{
  vertical-align: middle;
}
.hct-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block
}

.hct-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px
}

.hct-progress-steps {
  display: inline-block
}

.hct-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center
}

.hct-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all .3s
}

.hct-progress-steps-item-active {
  background: #1890ff
}

.hct-progress-small.hct-progress-line,
.hct-progress-small.hct-progress-line .hct-progress-text .anticon {
  font-size: 12px
}

.hct-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0
}

.hct-progress-show-info .hct-progress-outer {
  padding-right: 1em;
}

.hct-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #E5E3E9;
  border-radius: 100px
}

.hct-progress-circle-trail {
  stroke: #f5f5f5
}

.hct-progress-circle-path {
  -webkit-animation: hct-progress-appear .3s;
  animation: hct-progress-appear .3s
}

.hct-progress-inner:not(.hct-progress-circle-gradient) .hct-progress-circle-path {
  stroke: #1890ff
}

.hct-progress-success-bg,
.hct-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all .4s cubic-bezier(.08, .82, .17, 1) 0s
}

.hct-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #06C270
}

.hct-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #000000d9;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal
}

.hct-progress-text .anticon {
  font-size: 14px
}

.hct-progress-status-active .hct-progress-bg:before {
  position: absolute;
  inset: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: hct-progress-active 2.4s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: hct-progress-active 2.4s cubic-bezier(.23, 1, .32, 1) infinite;
  content: ""
}

.hct-progress-status-exception .hct-progress-bg {
  background-color: #ff4d4f
}

.hct-progress-status-exception .hct-progress-text {
  color: #ff4d4f
}

.hct-progress-status-exception .hct-progress-inner:not(.hct-progress-circle-gradient) .hct-progress-circle-path {
  stroke: #ff4d4f
}

.hct-progress-status-success .hct-progress-bg {
  background-color: #06C270
}

.hct-progress-status-success .hct-progress-text {
  color: #06C270
}

.hct-progress-status-success .hct-progress-inner:not(.hct-progress-circle-gradient) .hct-progress-circle-path {
  stroke: #06C270
}

.hct-progress-circle .hct-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent
}

.hct-progress-circle .hct-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%)
}

.hct-progress-circle .hct-progress-text .anticon {
  font-size: 1.16666667em
}

.hct-progress-circle.hct-progress-status-exception .hct-progress-text {
  color: #ff4d4f
}

.hct-progress-circle.hct-progress-status-success .hct-progress-text {
  color: #06C270
}

@-webkit-keyframes hct-progress-active {
  0% {
    transform: translate(-100%) scaleX(0);
    opacity: .1
  }

  20% {
    transform: translate(-100%) scaleX(0);
    opacity: .5
  }

  to {
    transform: translate(0) scaleX(1);
    opacity: 0
  }
}

@keyframes hct-progress-active {
  0% {
    transform: translate(-100%) scaleX(0);
    opacity: .1
  }

  20% {
    transform: translate(-100%) scaleX(0);
    opacity: .5
  }

  to {
    transform: translate(0) scaleX(1);
    opacity: 0
  }
}

.hct-progress-rtl {
  direction: rtl
}

.hct-progress-rtl.hct-progress-show-info .hct-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px)
}

.hct-progress-rtl .hct-progress-success-bg {
  right: 0;
  left: auto
}

.hct-progress-rtl.hct-progress-line .hct-progress-text,
.hct-progress-rtl.hct-progress-steps .hct-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right
}
.timeline-item-content-point{
  color: rgba(77, 60, 85, 0.65);
  width: 36px;
}
.dark-theme .timeline-item-content-point,
.dark-theme .timeline-item-content-desc{
  color:#fff;
}
.hct-status-text{
  text-align: right;
  color: rgba(77, 60, 85, 0.65);
}


.anticon-spin {
    display: inline-block;
    animation: loadingCircle 2.4s infinite linear;
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@keyframes loadingCircle{
  to{transform:rotate(360deg)
  }
}

.tips-wrap{
    border-top: 1px solid #E5E3E9;
    padding-top: 40px;
    font-size: 14px;
    text-align: center;
}
.view-on-explorer{
  text-decoration: underline;
  color: #5B8DEF;
  margin-bottom: 16px;
}
